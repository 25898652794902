<template lang="pug">
  v-container#user-profile(fluid='' tag='section')
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title
          h4 Registro de Proveedores
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="closeModal()")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-row(justify='center')
          v-col(cols='12' md='12')
            v-form(ref='formProvider' v-model='validProvider' lazy-validation='' autocomplete="random-string")
              v-container.py-0
                v-row
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(@keyup="provider.name = provider.name.toUpperCase()" :rules="requeridos" v-model='provider.name' label='Nombre del proveedor')
                  v-col(cols='12' md='4' v-if="profileLoaded ? !profileLoaded.cartotradesa : true")
                    v-text-field.purple-input(:rules='emailRules' v-model='provider.mail' label='Correo del proveedor')
                  v-col(cols='12' md='4' v-if="profileLoaded ? profileLoaded.cartotradesa : false")
                    v-text-field.purple-input(v-model='provider.mail' label='Correo del proveedor')
                  v-col(cols='12' md='4')
                    v-combobox(:rules="requeridos" v-model='provider.personType' :items='personTypeItems' label='Tipo de Personería')
                  v-col(cols='12' md='4' v-if="profileLoaded ? !profileLoaded.cartotradesa : true")
                    v-text-field.purple-input(:rules="requeridos" v-model='provider.ruc' label='RUC/CÉDULA' type="text" @keypress="isNumber($event)")
                  v-col(cols='12' md='4' v-if="profileLoaded ? profileLoaded.cartotradesa : false")
                    v-text-field.purple-input(v-model='provider.ruc' label='RUC/CÉDULA' type="text" @keypress="isNumber($event)")
                  v-col(cols='12' md='4' v-if="profileLoaded ? !profileLoaded.cartotradesa : true")
                    v-text-field.purple-input(:rules="requeridos" v-model='provider.phone' label='Celular del proveedor' type="text" @keypress="isNumber($event)")
                  v-col(cols='12' md='4' v-if="profileLoaded ? profileLoaded.cartotradesa : false")
                    v-text-field.purple-input(v-model='provider.phone' label='Celular del proveedor' type="text" @keypress="isNumber($event)")
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(v-model='provider.telephone' label='Teléfono del proveedor' type="text" @keypress="isNumber($event)")
                  v-col(cols="6")
                    v-text-field-simplemask(v-model='provider.guideFrom' label='Guía Desde' v-bind:properties="{\
                    prefix: '',\
                    suffix: '',\
                    }" v-bind:options="{\
                    inputMask: '###-###-#########',\
                    outputMask: '###############',\
                    alphanumeric: true,\
                    lowerCase: false,\
                    }" @keydown="isNumber($event)" v-if="profileLoaded ? profileLoaded.cartotradesa : false")
                  v-col(cols="6")
                    v-text-field-simplemask(v-model='provider.guideUntil' label='Guía Hasta' v-bind:properties="{\
                    prefix: '',\
                    suffix: '',\
                    }" v-bind:options="{\
                    inputMask: '###-###-#########',\
                    outputMask: '###############',\
                    alphanumeric: true,\
                    lowerCase: false,\
                    }" @keydown="isNumber($event)" v-if="profileLoaded ? profileLoaded.cartotradesa : false")
                  v-col(cols='12' md='12')
                    v-text-field.purple-input(v-model='provider.direction' label='Dirección del proveedor' type="text")
                  v-col.text-right(cols='12')
                    v-btn.mr-0(color='primary' @click='saveProvider')
                      | GUARDAR PROVEEDOR
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'
  import * as functions from '@/functions'
  export default {
    props: {
      providerToEdit: {
        default: function () {
          return {
            name: '',
            mail: '',
            personType: ['Natural'],
            ruc: '',
            telephone: '',
            phone: '',
            providerKey: '',
            direction: '',
            guideFrom: '',
            guideUntil: '',
            external: false,
          }
        },
        type: Object,
      },
    },
    data: () => ({
      emailRules: [
        v => !!v || 'Correo requerido',
        v => /\S+@\S+\.\S+/.test(v) || 'Email inválido',
      ],
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      requeridos: [
        value => !!value || 'Requerido',
      ],
      validProvider: true,
      provider: {
        name: '',
        mail: '',
        personType: ['Natural'],
        ruc: '',
        telephone: '',
        phone: '',
        providerKey: '',
        direction: '',
        guideFrom: '',
        guideUntil: '',
        external: false,
      },
      personTypeItems: [
        'Natural',
        'Jurídica',
      ],
    }),
    computed: {
      providersLst () {
        return this.$store.state.provider.lstProviders
      },
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
    },
    watch: {
      providerToEdit () {
        this.provider = this.providerToEdit
      },
    },
    mounted () {
      this.provider = this.providerToEdit
    },
    methods: {
      closeModal () {
        this.$emit('closeModal')
      },
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      async saveProvider () {
        if (this.$refs.formProvider.validate()) {
          // validacion para RUC o cedula
          let result = 0
          if (this.provider.ruc.toString() === '') {
            if (this.profileLoaded.cartotradesa) {
              result = '1'
            } else {
              result = 0
            }
          } else {
            if (this.provider.ruc.length > 10) { // se validac omo RUC
              result = functions.validIdentNumber(false, this.provider.ruc)
            } else { // se valida como cedula
              result = functions.validIdentNumber(true, this.provider.ruc)
            }
          }
          if (result.toString() === '1') {
            if (this.provider.providerKey === '' || !this.provider.providerKey) {
              const filterItem = this.providersLst.filter(item => {
                return (item.ruc === this.provider.ruc && item.ruc !== '')
              })
              if (filterItem.length > 0) {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Ya existe un proveedor con ese número de identificación',
                }
                return
              }
              this.provider.providerKey = firebase.database().ref(`providers/${this.$store.state.user.user.uid}`).push().getKey()
            }
            this.provider.completeData = this.provider.name + ' | ' + this.provider.ruc + ' | ' + this.provider.mail
            let banContinue = true
            if (this.profileLoaded.cartotradesa) {
              if (this.provider.guideFrom !== '' && this.provider.guideFrom.toString() !== '0' &&
                this.provider.guideUntil !== '' && this.provider.guideUntil.toString() !== '0') {
                const valInt1 = parseFloat(this.provider.guideFrom)
                const valInt2 = parseFloat(this.provider.guideUntil)

                if (valInt1 > valInt2) {
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'La guía desde no puede ser mayor a la guía hasta.',
                  }
                  return
                }
              }
              await this.$store.dispatch('provider/loadProviderByGuide', [this.provider.guideFrom, this.provider.guideUntil]).then(res => {
                if (res) {
                  if (res.length > 0) {
                    if (this.provider.id) {
                      if (res[0].id !== this.provider.id) {
                        this.snackbar = {
                          show: true,
                          color: 'red',
                          text: 'Error otro proveedor ya contine la guía des o hasta dentro de su configuración. Proveedor: ' +
                            res[0].completeData,
                        }
                        banContinue = false
                      }
                    } else {
                      this.snackbar = {
                        show: true,
                        color: 'red',
                        text: 'Error otro proveedor ya contine la guía des o hasta dentro de su configuración. Proveedor: ' +
                          res[0].completeData,
                      }
                      banContinue = false
                    }
                  }
                }
              })
            }
            if (banContinue) {
              await this.$store.dispatch('provider/setProvider', [this.provider.providerKey, this.provider]).then(async resolve => {
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Proveedor registrado correctamente',
                }
                this.$emit('closeModal')
              }, reject => {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al registrar el proveedor',
                }
              })
            }
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Verifique el número de identifación agregado',
            }
          }
        }
      },
    },
  }
</script>
